.mc-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 2rem auto;
  border-radius: 20px;
  background-color: var(--tertiary-color);
  color: #fff;
  animation: expand 2s ease forwards;
  position: relative;
  transition: all .8s ease;
}

.mc-contest {
  height: auto;
  overflow: hidden;
  font-weight: 600;
  color: var(--warning-color);
}

.mc-team {
  font-style: italic;
  color: #fff;
  line-height: 28px;
  transform: translateY(300px);
  animation: slideUp .8s ease-in-out forwards .8s;
  margin: auto 0.8rem;
}

/******************************** setting of button userFriends ***/
.mc-btn {
  text-align: center;
  position: relative;
  overflow: hidden;
  margin: 0.5rem;
  background: transparent;
  border: 0.1rem solid var(--warning-color);
  border-radius: 5rem;
  padding: 0.5rem 0.5rem;
  color: #fff;
  font-weight: 600;
  outline: none;
  animation: slideUp .8s ease-in-out forwards 1s;
  visibility: hidden;
  transition: all 0.4s;
}

.mc-btn::before {
  content: "";
  position: absolute;
  background-color: var(--contrast-color);
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  transition: all 0.4s;
  z-index: -1;
}

.mc-btn:hover::before {
  width: 100%;
}

.mc-btn:hover {
  color: aquamarine;
  scale: 1.1;
}

.mc-btn:active {
  scale: 1;
}

/******************************** setting of button userFriends ***/
.mc-userFriends {
  position: relative;
  border-radius: 1.5rem;
  border: 0.1rem solid var(--warning-color);
  text-align: center;
  padding: 0.5rem;
  transition: all 0.4s;
}

.mc-team button {
  background-color: transparent;
  border-style: none;
}

/* 
.mc-userFriends:hover {
  transform: translate(-3px, -3px);
}

.mc-userFriends:active {
  transform: translate(3px, 3px);
} */

.mc-userFriends::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  scale: 0;
  border-radius: 1.5rem;
  transition: all 0.4s;
  background-color: var(--contrast-color);
  z-index: -1;
}

.mc-team button svg {
  color: #fff;
}

.mc-userFriends:hover button svg {
  color: aquamarine;
}

.mc-userFriends:hover::before {
  scale: 1;
}

/**************/
.title {
  overflow: hidden;
  color: var(--contrast-color);
  /* margin-left: 2rem; */
  -webkit-text-stroke: 0.04rem #fff;
}

.title h1 {
  animation: slideUp .8s ease forwards .5s;
}

.mc-select {
  background-color: var(--contrast-color);
}

.mc-players-bg {
  background: url('../img/img-header/court-couverts-tennis.jpg') center center fixed no-repeat;
  background-size: cover;
  height: 100%;
  width: 90%;
  margin: 2rem 0;
}

.mc-players {
  background-color: rgba(21, 18, 38, 0.6);
  padding: 1rem;
  box-shadow: 0 0 8px #fff, 0 0 16px rgb(21, 18, 38), 0 0 32px rgb(21, 18, 38),
    inset 0 0 8px #fff, inset 0 0 16px rgb(21, 18, 38), inset 0 0 32px rgb(21, 18, 38);
}

@keyframes slideIn {
  0% {
    transform: translateX(500px) scale(.2);
  }

  100% {
    transform: translateX(0px) scale(1);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(500px);
  }

  100% {
    transform: translateY(0px);
    visibility: visible;
  }
}

@keyframes expand {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0px);
  }
}