body {
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* max-width: 1200px; */
    position:relative;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

:root {
    --red-color: #ff035b;
    --blue-color: #04d9ff;
    --green-color: green;
    --warning-color: #ffa500;
    --secondary-color: #151226;
    --tertiary-color: rgba(21, 18, 38, 0.8);
    --contrast-color: #BF307F;
    /* --x-pos1: Math.cos(0);
    --x-pos2: Math.cos((45 * Math.PI) / 180);
    --x-pos3: Math.cos((90 * Math.PI) / 180);
    --x-pos4: Math.cos((135 * Math.PI) / 180);
    --x-pos5: Math.cos((180 * Math.PI) / 180);
    --x-pos6: Math.cos((225 * Math.PI) / 180);
    --x-pos7: Math.cos((270 * Math.PI) / 180);
    --x-pos8: Math.cos((315 * Math.PI) / 180);
    --y-pos1: Math.sin(1);
    --y-pos2: Math.sin((45 * Math.PI) / 180);
    --y-pos3: Math.sin((90 * Math.PI) / 180);
    --y-pos4: Math.sin((135 * Math.PI) / 180);
    --y-pos5: Math.sin((180 * Math.PI) / 180);
    --y-pos6: Math.sin((225 * Math.PI) / 180);
    --y-pos7: Math.sin((270 * Math.PI) / 180);
    --y-pos8: Math.sin((315 * Math.PI) / 180); */
}

@import url('https://fonts.googleapis.com/css2?family=Rampart+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Wendy+One&display=swap');

.accordion-button:hover {
    background-color:#f8f8f8;
}

.accordion-button:hover {
    background-color:#f8f8f8;
}

.mc-rampart {
    font-family: "Rampart One", cursive;
    letter-spacing: 5px;
}

.mc-wendy {
    font-family: "Wendy One", cursive;
    letter-spacing: 5px;
}

/* responsive font size*/
.mc-rfs-1 {
    font-size: calc(0.8rem + 2vw);
}
.mc-rfs-2 {
    font-size: calc(0.8rem + 1.5vw);
}
.mc-rfs-3 {
    font-size: calc(0.8rem + 1vw);
}
.mc-rfs-4 {
    font-size: calc(0.8rem + 0.6vw);
}
.mc-rfs-5 {
    font-size: calc(0.8rem + 0.2vw);
}
.mc-rfs-6 {
    font-size: 0.8rem;
}

@media screen and (max-width: 576px) {
    /* responsive font size*/
    .mc-rfs-1 {
        font-size: calc(0.7rem + 2vw);
    }
    .mc-rfs-2 {
        font-size: calc(0.7rem + 1.5vw);
    }
    .mc-rfs-3 {
        font-size: calc(0.7rem + 1vw);
    }
    .mc-rfs-4 {
        font-size: calc(0.7rem + 0.6vw);
    }
    .mc-rfs-5 {
        font-size: calc(0.7rem + 0.2vw);
    }
    .mc-rfs-6 {
        font-size: calc(0.6rem + 0.5vw);
    }
    .mc-rfs-7 {
        font-size: calc(0.5rem + 0.5vw);
    }
}
@media screen and (max-width: 396px) {
   /* responsive font size*/
    .mc-rfs-1 {
        font-size: calc(0.6rem + 2vw);
    }
    .mc-rfs-2 {
        font-size: calc(0.6rem + 1.5vw);
    }
    .mc-rfs-3 {
        font-size: calc(0.6rem + 1vw);
    }
    .mc-rfs-4 {
        font-size: calc(0.6rem + 0.8vw);
    }
    .mc-rfs-5 {
        font-size: calc(0.6rem + 0.5vw);
    }
    .mc-rfs-6 {
        font-size: calc(0.4rem + 0.4vw);
    }
  }

.mc-banner {
    background: url('../img/img-header/tennis-terre-battue.png') center center fixed no-repeat;
    background-size: cover;
}

/* paramétrage nécessaire car la classe nav-link est déclarée à 100% dans menu-club */
.mc-banner .nav-link {
    width: auto;
}

.footer {
    background-color: rgba(135, 209, 249, 0.2);
}

/* 
.mc-navbar {
    background-color: rgba(255, 110, 253, 0.5);
    font-size: 3.3vw;
} */

.mc-tcf-email,
.mc-footer-link,
.mc-nous-contacter {
    color: gray;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: small;
}

.mc-tcf-email:hover,
.mc-footer-link:hover,
.mc-nous-contacter:hover {
    text-decoration-line: underline;
    font-weight: 600;
    color: blue;
}

.dropdown-item {
    font-size: small;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.mc-fond-ecran {
    background-color: rgba(135, 209, 249, 0.3);
    padding-bottom: 30px;
}

.mc-fond-clubhouse {
    background: url("../img/img-notrehistoire/clubHouse3.jpg") center center fixed no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mc-fond-admin {
    position: relative;
    background: url("../../assets/img/img-bureau/admin-640x427.jpg") center center fixed no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mc-fond-programme {
    background: url("../img/img-animations/05_programme.png") center center fixed no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mc-fond-pickleball {
    background: url("../img/img-animations/06_pickleball.png") center center fixed no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mc-fond-badminton {
    background: url("../img/img-animations/01_badminton.png") center center fixed no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mc-fond-courts-couverts {
    background: url("../img/img-installations/courtsCouvert-657x494.jpg") center center fixed no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mc-fond-fromage {
    background: url("../img/img-animations/02_venteFromageVin.png") center center fixed no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.mc-fond-rg {
    background: url("../img/img-rg2013/01-imgRg-central-1000x448.png") center center fixed no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

/*************************************************************/
/**************** WORK IN PROGRESS FLASHING ******************/
/*************************************************************/

.mc-flashing-text {
    font-family: 'Commissioner', sans-serif;
    font-size: 50px;
    font-weight: 700;
    color: #ffd6d6;
    padding: 60px;
    line-height: 2;
    border: 10px solid #c6e2ff;
    border-radius: 50%;
    text-transform: uppercase;
    animation: flashing 1.4s infinite alternate;
  }
      
  @keyframes flashing {
      0%, 18%, 21%, 32%, 35%, 39%, 42%, 100% {
        text-shadow:
          0 0 5px #ffd6d6,
          0 0 15px #ffd6d6,
          0 0 30px #ff035b,
          0 0 40px #ff035b,
          0 0 50px #ff035b;
        box-shadow:
          0 0 8px #fff,
          inset 0 0 8px #fff,
          0 0 32px #04d9ff,
          inset 0 0 32px #04d9ff,
          0 0 64px #04d9ff,
          inset 0 0 64px #04d9ff;
      }
      20%, 34%, 41% {        
        text-shadow: none;
        box-shadow: none;
      }
  }

  .mc-btn-neon {
    border-width: 0;
    box-shadow:
    0 0 1px 1px rgba(255, 255, 255, 0.68),
    inset 0 0 1px 1px rgba(255, 255, 255, 0.68),
    0 0 4px 4px rgba(170,170,170,0.68),
    inset 0 0 2px 2px rgba(170,170,170,0.68),
    0 0 8px 8px #AAAAAA,
    inset 0 0 4px 4px #AAAAAA;
}

/* ======================= ANIMATION LOGO ================================ */

.mc-page-logo {
    text-align: center;
    height: auto;
}

.mc-position-logo {
    position: relative;
    /* left: 35%; */
    top: 40%;
    width: 30%;
    animation: animation-logo 3s ease 0.5s forwards;
}

@keyframes animation-logo {
    30% {
        transform: rotate(720deg);
        top: 20%;
    }

    100% {
        transform: translateX(480%) translateY(-100%);
        scale: 0.3;
        top: 5%;
    }
}


/* ========================== PARAMETRAGE DES MARGES D'IMPRESSION ====================== */

@page {
    margin: 3rem;
}

/* ========================== PARAMETRAGE DES TOOLTIPS SUR LES BOUTONS ================= */

.tooltip-inner {
    background-color: #66020a;
    color: #fff;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color: #fff;
}

@media screen and (max-width: 270px) {
    .mc-page-logo {
        height: 50vh;
    }
}


/* ========================== PARAMETRAGE DES TOOLTIPS SUR LES BOUTONS ================= */


.mc-invisible {
    animation: mc-fade-out 2s ease-out 0.3s forwards;
}

@keyframes mc-fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* ========================== PARAMETRAGE POUR FAIRE CLIGNOTER UNE DIV ================= */

.mc-div-clignote {
    animation-duration: 1s;
    animation-name: clignoter;
    animation-iteration-count: infinite;
    transition: none;
 }
 
 @keyframes clignoter {
   0%   { opacity:1; }
   40%   {opacity:0; }
   100% { opacity:1; }
 }