/* Reparamétrage des variables Bootstrap */
.table thead {
  --bs-table-bg: rgba(110, 43, 110, 1);
  --bs-table-color: white;
}

.table tbody {
  --bs-table-bg: rgba(0, 43, 110, 0.7);
  --bs-table-color: white;
}

.mc-table {
    table-layout: fixed;
    width: 100%;
    /* font-size: 1.2vmax; */
  }
  
.mc-table thead {
    text-align:center;
  }

.mc-table thead th:nth-child(1) {
    width: 20%;
  }
  
.mc-table thead th:nth-child(2) {
    width: 30%;
  }
  
.mc-table thead th:nth-child(3) {
    width: 16%;
  }
  
.mc-table thead th:nth-child(4) {
    width: 16%;
  }

.mc-table thead th:nth-child(5) {
  width: 18%;
}
  
.mc-table th,
  td {
    padding: 10px;
  }

.mc-table
  td:nth-child(3),
  td:nth-child(4) {
    text-align: center;
  }

  @media screen and (max-width: 991px) {
    .mc-table {
      font-size: 0.9rem
    }
  }

  @media screen and (max-width: 767px) {
    .mc-table {
      font-size: 0.8rem
    }
  }
  
  @media screen and (max-width: 575px) {
    .mc-table {
      font-size: 0.7rem
    }
  }


