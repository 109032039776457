
/* ========================== ANIMATION BOUTON RESERVATION ================= */
.mc-btn-resa {
    position: relative;
    background: rgba(black, .1);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border: 2px solid white;
    border-radius: 10px;
    text-decoration: none;
    color: rgba(white, .95);
    cursor: pointer;
    width: 20vw;
    max-width: 15vw;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s all ease-in-out;
}

.mc-btn-resa:hover {
    background: rgba(black, 0.2);
    box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.85);
    text-transform: uppercase;
}

/************************************************************/
/*************** Point lumineux *****************************/
/************************************************************/

.mc-flashing-point1,
.mc-flashing-point2 {
    position: absolute;
    height: 8px;
    width: 8px;
    top: -3px;
    left: -3px;
    border-radius: 50%;
    background-color:white;
    
    box-shadow:
    0 0 8px #fff,
    inset 0 0 8px #fff,
    0 0 16px #c6e2ff,
    inset 0 0 8px #c6e2ff,
    0 0 32px #c6e2ff,
    inset 0 0 16px #c6e2ff;
}

.mc-flashing-point1 {
    animation: 4s linear infinite slideinright;
    -webkit-animation: 4s linear infinite slideinright;
}

.mc-flashing-point2 {
    animation: 4s linear infinite slideinleft;
    -webkit-animation: 4s linear infinite slideinleft;
  }

/************************************************************/
/*************** Écran XL et plus ***************************/
/************************************************************/
  
  @-webkit-keyframes slideinright {
    0% {
        transform: translate(-3px, 0px);
    }
    25% {
        transform: translate(calc(15vw - 3px), 0px);
    }
    50% {
        transform: translate(calc(15vw - 3px), calc(4vh - 3px));
    }
    75% {
        transform: translate(-3px, calc(4vh - 3px));
    }
    100% {
        transform: translate(-3px, 0px);
    }
  }

  @keyframes slideinright {
    0% {
        transform: translate(-3px, -3px);
    }
    25% {
        transform: translate(calc(15vw - 3px), 0px);
    }
    50% {
        transform: translate(calc(15vw - 3px), calc(4vh - 3px));
    }
    75% {
        transform: translate(-3px, calc(4vh - 3px));
    }
    100% {
        transform: translate(-3px, 0px);
    }
  }

  @-webkit-keyframes slideinleft {
    0% {
        transform: translate(15vw, -3px);
    }
    25% {
        transform: translate(-3px, -3px);
    }
    50% {
        transform: translate(-3px, calc(4vh - 3px));
    }
    75% {
        transform: translate(calc(15vw - 3px), calc(4vh - 3px));
    }
    100% {
        transform: translate(calc(15vw - 3px), -3px);
    }
  }

  @keyframes slideinleft {
    0% {
        transform: translate(15vw, -3px);
    }
    25% {
        transform: translate(-3px, -3px);
    }
    50% {
        transform: translate(-3px, calc(4vh - 3px));
    }
    75% {
        transform: translate(calc(15vw - 3px), calc(4vh - 3px));
    }
    100% {
        transform: translate(calc(15vw - 3px), -3px);
    }
  }

/************************************************************/
/*************** Écran LG ***********************************/
/************************************************************/

@media screen and (max-width: 767px) {
    .mc-btn-resa {
        min-width: 25vw;
    }

    @-webkit-keyframes slideinright {
        0% {
            transform: translate(-3px, 0px);
        }
        25% {
            transform: translate(calc(25vw - 3px), 0px);
        }
        50% {
            transform: translate(calc(25vw - 3px), calc(4vh - 3px));
        }
        75% {
            transform: translate(-3px, calc(4vh - 3px));
        }
        100% {
            transform: translate(-3px, 0px);
        }
      }
    
      @keyframes slideinright {
        0% {
            transform: translate(-3px, -3px);
        }
        25% {
            transform: translate(calc(25vw - 3px), 0px);
        }
        50% {
            transform: translate(calc(25vw - 3px), calc(4vh - 3px));
        }
        75% {
            transform: translate(-3px, calc(4vh - 3px));
        }
        100% {
            transform: translate(-3px, 0px);
        }
      }
    
      @-webkit-keyframes slideinleft {
        0% {
            transform: translate(25vw, -3px);
        }
        25% {
            transform: translate(-3px, -3px);
        }
        50% {
            transform: translate(-3px, calc(4vh - 3px));
        }
        75% {
            transform: translate(calc(25vw - 3px), calc(4vh - 3px));
        }
        100% {
            transform: translate(calc(25vw - 3px), -3px);
        }
      }
    
      @keyframes slideinleft {
        0% {
            transform: translate(25vw, -3px);
        }
        25% {
            transform: translate(-3px, -3px);
        }
        50% {
            transform: translate(-3px, calc(4vh - 3px));
        }
        75% {
            transform: translate(calc(25vw - 3px), calc(4vh - 3px));
        }
        100% {
            transform: translate(calc(25vw - 3px), -3px);
        }
     }
}

/************************************************************/
/*************** Écran MG ***********************************/
/************************************************************/

@media screen and (max-width: 576px) {
    .mc-btn-resa {
        width: 25vw;
        min-width: 30vw;
    }
    @-webkit-keyframes slideinright {
        0% {
            transform: translate(-3px, 0px);
        }
        25% {
            transform: translate(calc(30vw - 3px), 0px);
        }
        50% {
            transform: translate(calc(30vw - 3px), calc(4vh - 3px));
        }
        75% {
            transform: translate(-3px, calc(4vh - 3px));
        }
        100% {
            transform: translate(-3px, 0px);
        }
      }
    
      @keyframes slideinright {
        0% {
            transform: translate(-3px, -3px);
        }
        25% {
            transform: translate(calc(30vw - 3px), 0px);
        }
        50% {
            transform: translate(calc(30vw - 3px), calc(4vh - 3px));
        }
        75% {
            transform: translate(-3px, calc(4vh - 3px));
        }
        100% {
            transform: translate(-3px, 0px);
        }
      }
    
      @-webkit-keyframes slideinleft {
        0% {
            transform: translate(30vw, -3px);
        }
        25% {
            transform: translate(-3px, -3px);
        }
        50% {
            transform: translate(-3px, calc(4vh - 3px));
        }
        75% {
            transform: translate(calc(30vw - 3px), calc(4vh - 3px));
        }
        100% {
            transform: translate(calc(30vw - 3px), -3px);
        }
      }
    
      @keyframes slideinleft {
        0% {
            transform: translate(30vw, -3px);
        }
        25% {
            transform: translate(-3px, -3px);
        }
        50% {
            transform: translate(-3px, calc(4vh - 3px));
        }
        75% {
            transform: translate(calc(30vw - 3px), calc(4vh - 3px));
        }
        100% {
            transform: translate(calc(30vw - 3px), -3px);
        }
     }
}

/************************************************************/
/*************** Écran SM ***********************************/
/************************************************************/

@media screen and (max-width: 396px) {
    .mc-btn-resa {
        width: 30vw;
        max-width: 30vw;
        min-width: 35vw;
        height: 3.5vh;
    }

    @-webkit-keyframes slideinright {
        0% {
            transform: translate(-3px, 0px);
        }
        25% {
            transform: translate(calc(35vw - 3px), 0px);
        }
        50% {
            transform: translate(calc(35vw - 3px), calc(3.5vh - 3px));
        }
        75% {
            transform: translate(-3px, calc(3.5vh - 3px));
        }
        100% {
            transform: translate(-3px, 0px);
        }
      }
    
      @keyframes slideinright {
        0% {
            transform: translate(-3px, -3px);
        }
        25% {
            transform: translate(calc(35vw - 3px), 0px);
        }
        50% {
            transform: translate(calc(35vw - 3px), calc(3.5vh - 3px));
        }
        75% {
            transform: translate(-3px, calc(3.5vh - 3px));
        }
        100% {
            transform: translate(-3px, 0px);
        }
      }
    
      @-webkit-keyframes slideinleft {
        0% {
            transform: translate(35vw, -3px);
        }
        25% {
            transform: translate(-3px, -3px);
        }
        50% {
            transform: translate(-3px, calc(3.5vh - 3px));
        }
        75% {
            transform: translate(calc(35vw - 3px), calc(3.5vh - 3px));
        }
        100% {
            transform: translate(calc(35vw - 3px), -3px);
        }
      }
    
      @keyframes slideinleft {
        0% {
            transform: translate(35vw, -3px);
        }
        25% {
            transform: translate(-3px, -3px);
        }
        50% {
            transform: translate(-3px, calc(3.5vh - 3px));
        }
        75% {
            transform: translate(calc(35vw - 3px), calc(3.5vh - 3px));
        }
        100% {
            transform: translate(calc(35vw - 3px), -3px);
        }
     }
}

/************************************************************/
/*************** Écran XS ***********************************/
/************************************************************/

@media screen and (max-width: 270px) {
    .mc-btn-resa {
        width: 35vw;
        min-width: 40vw;
        height: 4vh;
    }

    @-webkit-keyframes slideinright {
        0% {
            transform: translate(-3px, 0px);
        }
        25% {
            transform: translate(calc(40vw - 3px), 0px);
        }
        50% {
            transform: translate(calc(40vw - 3px), calc(4vh - 3px));
        }
        75% {
            transform: translate(-3px, calc(4vh - 3px));
        }
        100% {
            transform: translate(-3px, 0px);
        }
      }
    
      @keyframes slideinright {
        0% {
            transform: translate(-3px, -3px);
        }
        25% {
            transform: translate(calc(40vw - 3px), 0px);
        }
        50% {
            transform: translate(calc(40vw - 3px), calc(4vh - 3px));
        }
        75% {
            transform: translate(-3px, calc(4vh - 3px));
        }
        100% {
            transform: translate(-3px, 0px);
        }
      }
    
      @-webkit-keyframes slideinleft {
        0% {
            transform: translate(40vw, -3px);
        }
        25% {
            transform: translate(-3px, -3px);
        }
        50% {
            transform: translate(-3px, calc(4vh - 3px));
        }
        75% {
            transform: translate(calc(40vw - 3px), calc(4vh - 3px));
        }
        100% {
            transform: translate(calc(40vw - 3px), -3px);
        }
      }
    
      @keyframes slideinleft {
        0% {
            transform: translate(40vw, -3px);
        }
        25% {
            transform: translate(-3px, -3px);
        }
        50% {
            transform: translate(-3px, calc(4vh - 3px));
        }
        75% {
            transform: translate(calc(40vw - 3px), calc(4vh - 3px));
        }
        100% {
            transform: translate(calc(40vw - 3px), -3px);
        }
     }
}