/* ======================= WEATHER ================================ */

.mc-weather {
    background: url('../img/img-weather/sun-clouds_1920.jpg') center center;
    background-size: cover;
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 10px;
    border: 2px solid rgb(2, 57, 141);
    box-shadow: inset 0 0 30px rgb(2, 57, 141);
}

.mc-wsearch {
    text-align: center;
    /* padding: 1rem; */
    width: 95%;
}

/* .mc-winput {
    width: 100%;
    padding: 0 1rem;
    font-size: 1.5rem;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.4);
} */

::placeholder {
    color: #f8f8f8;
}

.mc-wtop {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 0.5rem auto;
    font-size: 1.6rem;
    border-radius: 25px;
    border: 2px solid rgb(255, 255, 0);
    background: rgba(2, 57, 141, 0.5);
    box-shadow:
        0 0 10px rgb(2, 57, 141),
        0 0 20px rgb(2, 57, 141),
        0 0 30px rgb(2, 57, 141),
        inset 0 0 20px rgb(2, 57, 141);
}

/* .mc-wlocation{
    font-size: 1.5rem;
    color: #2b2b2b;
} */

.mc-wtemp {
    font-size: 2rem;
}

.mc-wdesc {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 1rem;
    transform: rotate(270deg);
    background: rgba(2, 57, 141, 0.5);
    border: 2px solid rgba(255, 255, 255, 0.8);
}

.mc-wbottom {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
    width: 100%;
    margin: 0.5rem auto;
    padding: 0.5rem;
    border-radius: 10px;
    background-color: rgba(2, 57, 141, 0.5);
    border: 2px solid rgba(255, 255, 255, 0.8);
    font-weight: 700;
    font-size: 0.8rem;
}

/* ========================== ANIMATION TEXTE ============================= */

.mc-stripesup,
.mc-stripesdown {
    background-image: -webkit-linear-gradient(black 50%, white 50%);
    background-size: 100% 2rem;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.mc-stripesup {
    -webkit-animation: stripesup 2s linear infinite;
      animation: stripesup 2s linear infinite;
  }

  
    @-webkit-keyframes stripesup {
        100% {
            background-position: 0 2rem;
        }
    }
    @keyframes stripesup {
        100% {
            background-position: 0 2rem;
        }
    }
    
.mc-stripesdown {
    -webkit-animation: stripesdown 2s linear infinite;
    animation: stripesdown 2s linear infinite;
  }

    @-webkit-keyframes stripesdown {
        100% {
        background-position: 0 -2rem;
        }
    }

    @keyframes stripesdown {
        100% {
        background-position: 0 -2rem;
        }
    }

/* ========================== INFINITE PARTNERS CAROUSEL ============================= */

.mc-neon {
    margin: auto !important;
    background-color: #fff;
    width: 98% !important;
    border: 4px solid #fff;
    border-radius: 5px;
    box-shadow: 0 0 8px #fff, 0 0 16px rgb(13, 110, 253), 0 0 32px rgb(13, 110, 253);
}

.mc-logos {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    position: relative;
}

.mc-logos:before,
.mc-logos:after {
    content: "";
    position: absolute;
    top: 0;
    width: 200px;
    height: 100%;
    z-index: 2;
}

.mc-logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.mc-logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.mc-logos:hover .mc-logos-slide {
    animation-play-state: paused;
}

.mc-logos-slide {
    display: flex;
    animation: 20s defilement infinite linear;
}

.mc-logos-img {
    height: 10rem;
    margin: 10px;
}

@keyframes defilement {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

/* ========================== PARTNERS TITLE ============================= */

.mc-partners {
    letter-spacing: 0;
    margin: 0 auto;

    /* Clip Background Image */
    background: url('../img/img-partners/animated-text-fill-light-blue.png') no-repeat;
    -webkit-background-clip: text;
    background-clip: text;

    /* Animate Background Image */
    -webkit-text-fill-color: transparent;
    animation: aitf 60s linear infinite;
}

.mc-txt-animated-orange {
    letter-spacing: 0;
    margin: 0 auto;

    /* Clip Background Image */
    background: url('../img/img-partners/animated-text-fill-orange.png') no-repeat;
    -webkit-background-clip: text;
    background-clip: text;

    /* Animate Background Image */
    -webkit-text-fill-color: transparent;
    animation: aitf 60s linear infinite;
}

/* Animate Background Image */

@keyframes aitf {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}
