/* ======================= MENU CLUB - LARGE SCREEN ================================ */

.mc-menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    left: -100%;
    height: 100%;
    background: rgba(0, 43, 110, 0.7);
    border-right: #fff solid 1px;
    animation: menu-visible 1s linear forwards;
}

.mc-active-link {
    color: #fff;
    font-weight: 800;
    border-radius: 5px;
    background-color: rgba(2, 55, 146, 0.5);
    box-shadow: 0 0 8px rgb(255, 255, 255);
}

@keyframes menu-visible {
    100% {
        transform: translateX(100%);
    }
}

.mc-menu-club {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* text-align: center; */
    padding-top: 1rem;
    height: 100%;
}

/*** marker pour positionner les ancrages sous l'entête fixe ******/
.mc-marker {
    display: inline-block;
    position: absolute;
}

.mc-top-page:hover {
    color: #fff;
    font-weight: 600;
    box-shadow: 0 0 8px rgb(103, 155, 255);
}

.mc-menu-item {
    width: 100%;
    padding: 0px 10px;
    height: auto;
}

.nav-link {
    width: 100%;
}

.mc-menu-club a {
    color: #fff;
    font-weight: 400;
    display: flex;
    justify-content:center;
    align-items: center;
    margin: 4vh 0;
}

.mc-nav-hover {
    position: relative;
}

.mc-menu-club a:hover,
.mc-nav-hover a:hover {
    color: #fff;
    font-weight: 600;
    background-color: rgb(103, 155, 255);
    box-shadow: 0 0 8px rgb(255, 255, 255);
}

.mc-menu-burger {
    width: 38px;
    height: 2rem;
    background: rgb(0, 43, 110);
    border-radius: 0.3rem;
    border: #fff solid 2px;
    color: inherit;
    display: none;
    position: fixed;
}

/********************************************** view medium screen *********************/

@media screen and (max-width: 767px) {
    .navbar {
        font-size: 0.8rem;
    }

    .mc-menu {
        justify-content: center;
        border-right: none;
        border-bottom: rgb(155, 165, 180) solid 1px;
        top: 0;
        padding: 0;
        background: none;
        height: 100%;
    }

    .mc-menu-club {
        padding: 0;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        background: rgba(0, 43, 110, 0.8);
        border-bottom: rgb(155, 165, 180) solid 1px;
    }

    .mc-menu-club a {
        padding: 4px;
        margin: 4px;
        --bs-nav-link-padding-y: 0;
    }

    .mc-menu-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* height: auto; */
        width: 100%;
    }

    .mc-active-link {
        text-align: center;
    }
}

/********************************************** view small screen *********************/

@media screen and (max-width: 270px) {
    .footer .nav {
        flex-direction: column;
    }

    .mc-menu {
        align-content: flex-start;
        justify-content: flex-start;
        background: rgba(0, 43, 110, 0.8);
    }
    
    .mc-menu-club {
        flex-direction: column;
        justify-content: start;
        align-items: center;
        position: absolute;
        top: 0;
        width: 0;
        visibility: hidden;
        background: rgba(0, 43, 110, 0.8);
        transition: all 0.5s linear;
        height: 100vh;
    }
    
    .mc-show-nav .mc-menu-club {
        width: 75vw;
        overflow: hidden;
        visibility: visible;
        transition: all 0.5s linear;
    }

    .mc-menu-club a {
        display: block;
        font-size: 4vw;
    }

    .mc-menu-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        background: none;
        border-bottom: none;
        margin-top: 4vh;
    }

    .mc-menu-item:last-child::after {
        display: none;
    }

    .mc-active-link {
        color: #fff;
        font-weight: 800;
        border-radius: 5px;
        background-color: rgba(2, 55, 146, 0.5);
        box-shadow: 0 0 8px rgb(255, 255, 255);
    }

    .mc-menu-burger {
        display: flex;
        align-items: center;
        position: relative;
        margin: 5px;
    }

    .mc-menu-burger:hover {
        cursor: pointer;
    }

    .mc-burger-bar,
    .mc-burger-bar::before,
    .mc-burger-bar::after {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 3px;
        background: #fff;
        transition: all .5s ease;
    }
    
    .mc-burger-bar::before,
    .mc-burger-bar::after {
        content: "";
        position: absolute;
    }

    .mc-burger-bar::before {
        transform: translateY(-5px);
    }

    .mc-burger-bar::after {
        transform: translateY(5px);
    }

    .mc-show-nav .mc-menu-burger {
        background: rgb(103, 155, 255);
        transition: all 1s linear;
    }
    
    .mc-show-nav .mc-burger-bar {
        background: transparent;
        /* width: 0; */
        transition: all 1s linear;
    }

    .mc-show-nav .mc-burger-bar::before {
        transform: rotate(45deg);
    }

    .mc-show-nav .mc-burger-bar::after {
        transform: rotate(-45deg);
    }

    .mc-menu-item {
        transform: translateY(100vh);
    }

    .mc-show-nav .mc-menu-item {
        transform: translateY(0);
    }

    .mc-show-nav .mc-slideInDown-1 {
        transition: all 1s ease-out;
    }
    .mc-show-nav .mc-slideInDown-2 {
        transition: all 1.1s ease-out;
    }
    .mc-show-nav .mc-slideInDown-3 {
        transition: all 1.2s ease-out;
    }
    .mc-show-nav .mc-slideInDown-4 {
        transition: all 1.3s ease-out;
    }
    .mc-show-nav .mc-slideInDown-5 {
        transition: all 1.4s ease-out;
    }
    .mc-show-nav .mc-slideInDown-6 {
        transition: all 1.5s ease-out;
    }
    .mc-show-nav .mc-slideInDown-7 {
        transition: all 1.6s ease-out;
    }
    .mc-show-nav .mc-slideInDown-8 {
        transition: all 1.7s ease-out;
    }
    .mc-show-nav .mc-slideInDown-9 {
        transition: all 1.8s ease-out;
    }
}

/*********************** Scrollbar ************************************/

.mc-scrollbar {
    overflow: auto;
    scrollbar-width: none; /* for Firefox */
}

.mc-scrollbar::-webkit-scrollbar { /* for Google, Safari and other browsers, .... */
    display: none;
}

.mc-navref {
    position: relative;
    scroll-behavior: smooth;
}
