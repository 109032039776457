/* breadcrumb */
.mc-breadcrumb {
  padding-inline-start: 1rem;
  background: rgba(0, 43, 110, 1);
  position: relative;
  font-size: 0.8rem;
  --bs-breadcrumb-divider: "";
}

.mc-breadcrumb-item:hover {
  text-decoration: underline white;
}

.mc-breadcrumb-item {
  text-decoration:none;
  color:aliceblue;
  padding-inline: 0.5rem;
}

.mc-breadcrumb-item-active {
  background-color: white;
  font-weight: bold;
  text-decoration: none;
  color: gray;
  padding: 0 0.4rem 0 0.4rem;
}

@media (max-width: 767px) {
  .mc-breadcrumb {
    border-bottom: rgb(155, 165, 180) solid 1px;
  }
}