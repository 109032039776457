
/* ======================= LE CLUB ================================ */

.mc-container-club {
    padding: 2rem;
    margin: 2rem auto;
    height: 100%;
    border-radius: 20px;
    background-color: rgba(21, 18, 38, 0.6);
    overflow: hidden;
    animation: slideContainerUp 2s ease forwards;
}

.mc-container-notrehistoire1 {
    transform: translateY(100vh);
    animation: slideContainerUp 1s ease forwards .5s;
}

.mc-container-notrehistoire2 {
    transform: translateY(100vh);
    animation: slideContainerUp 1s ease forwards 1s;
}

.mc-container-notrehistoire3 {
    transform: translateY(100vh);
    animation: slideContainerUp 1s ease forwards 1.5s;
}

.mc-container-notrehistoire4 {
    transform: translateY(100vh);
    animation: slideContainerUp 1s ease forwards 2s;
}

.mc-container-notrehistoire5 {
    transform: translateY(100vh);
    animation: slideContainerUp 1s ease forwards 2.5s;
}

.mc-title {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1.2rem;
    color: blue;
    border-radius: 20px;
    animation: expandTitle 1s linear forwards;
}

@keyframes expandTitle {
    0% {
        transform: scale(0.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes slideContainerUp {
    0% {
        transform: translateY(100vh);
    }

    100% {
        transform: translateY(0px);
    }
}

@media screen and (max-width: 768px) {
    .mc-container-club {
        padding: 1rem;
        margin: 1rem auto;
    }
}
@media screen and (max-width: 270px) {
    .mc-container-club {
        padding: 0;
        margin: 0;
    }
}

/* ======================= NOTRE notreHISTOIRE ================================ */

.mc-les3Presidents {
    border: 2px solid white;
    box-shadow: inset 0 0 30px black;
    border-radius: 25px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mc-img-declaration {
    box-shadow: 0 0 10px gray, inset 0 0 10px gray;
    width: 50%;
}

.mc-card {
    background-color: rgba(0, 43, 110, 0.5) !important;
}

/* ========================== OÙ SOMMES-NOUS ? ============================= */

.mc-fond-europe {
    background: url("../img/img-header/europe-map.png") center center fixed no-repeat;
    background-size: cover;
}

.mc-osn-point-visible {
    font-size: 0.5rem;
    text-align: center;
    padding: 0 0.3rem;
    border-radius: 5%;
    position: fixed;
    top: 43%;
    left: 40%;
    font-weight: 600;
}

.mc-osn-point1-visible {
    color: rgb(96, 96, 2);
    background-color: rgba(255, 255, 134, 0.6);
    animation: animation-interrogation-point 4s linear infinite;
}

.mc-osn-point2-visible {
    color: rgb(90, 1, 1);
    background-color: rgba(255, 148, 148, 0.6);
    animation: animation-exclamation-point 3s linear infinite;
}

.mc-osn-point-invisible {
    visibility: hidden;
}

.mc-osn-map-visible {
    position: fixed;
    left: 55%;
    top: 43%;
    width: 13%;
    z-index: 2000;
    animation: animation-map 2s ease forwards;
}

.mc-osn-map-invisible {
    visibility: hidden;
}

@keyframes animation-interrogation-point {
    100% {
        scale: 3;
        transform: translateX(-3vw);
    }
}

@keyframes animation-exclamation-point {
    100% {
        scale: 3;
        transform: translateX(3vw);
    }
}

@keyframes animation-map {
    100% {
        scale: 4.5;
    }
}

/* ========================== ANIMATION BUREAU ============================= */

.mc-bureau {
    /* height: 100%; */
    width: 75%;
    padding: 2rem;
}

.mc-wheel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #ff035b;
    height: 600px;
    width: 600px;
    color: #fff;
    border-radius: 50%;
}

@keyframes turnin {
    from {
        transform: translate(-50%, -50%) rotate(0);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes turnout {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}